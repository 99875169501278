import React from "react";
import { mapping } from "@aim-mf/styleguide";
import { NewRiskForm } from "./newRiskForm";
import { RiskTable } from "./riskTable";

const RiskCreationFormStyle = {
  position: "relative",
  left: "-50%",
};
const RiskCreationFormWrapStyle = {
  position: "absolute",
  left: "50%",
  top: "6rem",
};
const BlurEffectStyle = {
  filter: "blur(5px)",
};

const riskTableWrapper = {
  marginLeft: "15.6rem",
  marginRight: "15.6rem",
  marginTop: "3.9rem",
  height: "fit-content",
  width: "fir-content",
  backgroundColor: "rgba(0,0,0,0.4)",
};

const tableTitlePadding = {
  paddingLeft: "1rem",
  paddingTop: "1rem",
};

const titleStyle = {
  marginBottom: "1rem",
  ...mapping["heading/h5/lightleft"],
};

class RiskManagement extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      createNewRisk: false,
    };
  }

  createRiskHandler = () => {
    // this.props.handleUserListRequest();
    this.setState({ createNewRisk: true });
  };

  closeCreationForm = () => {
    this.setState({ createNewRisk: false });
  };

  render() {
    let parentRisk = this.props.riskList.filter((risk) => {
      return risk.isParentRisk;
    });
    return (
      <div>
        <div
          style={
            this.state.createNewRisk ? BlurEffectStyle : { dummy: "dummy" }
          }
        >
          <div style={riskTableWrapper}>
            <div style={tableTitlePadding}>
              <div style={titleStyle}>Risk Inventory</div>
              <div className="risk-card-subtitle">
                This manages and register the systems' Risks and Sub Risks
              </div>
            </div>
            <RiskTable
              data={this.props.riskList}
              addNew={this.createRiskHandler}
              archive={this.props.handleArchiveRisk}
              rights={this.props.rights}
            />
          </div>
        </div>
        {this.state.createNewRisk && (
          <div style={RiskCreationFormWrapStyle}>
            <div style={RiskCreationFormStyle}>
              <NewRiskForm
                parentRiskList={parentRisk}
                closeCreationForm={this.closeCreationForm}
                riskList={this.props.riskList}
                groupRiskList={this.props.groupRiskList}
                populateRiskDetail={this.props.populateRiskDetail}
                userList={this.props.userList}
                handleNewRiskSubmit={this.props.handleNewRiskSubmit}
                handleSearchGroupRisk={this.props.handleSearchGroupRisk}
                handleriskDetailRequest={this.props.handleriskDetailRequest}
                riskListFromGroup={this.props.riskListFromGroup}
                riskAutoPopulateDetail={this.props.riskAutoPopulateDetail}
                searching={this.props.searching}
              />
            </div>
          </div>
        )}
      </div>
    );
  }
}

export { RiskManagement };
